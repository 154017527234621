import { PROFILE, APP_STATE } from '../actionTypes';
import { saveToken, logoutStorage, saveProfile, getProfileFromStorage } from '../../services/storageService';
import * as profileApi from '../../services/profileApi';


export function register(myRequest) {

	return async (dispatch) => {
		try {
			let response = await profileApi.register(myRequest);

			if (response.data.hasError === false) {
				// we are good to go				
				if (response.data.token !== undefined) {

					saveToken(response.data.token);
					saveProfile(JSON.stringify(response.data.profile));

					dispatch(
						{
							type: PROFILE.SET_PROFILE,
							payload: response.data.profile
						}
					);
					return response;
				}
			} else {
				return response;
			}
		} catch (e) {
			return e;
		}
	};
}



export function login(myRequest) {

	return async (dispatch) => {
		try {

			let response = await profileApi.login(myRequest);
			if (response.data.hasError === false) {
				// we are good to go, save token and payload
				if (response.data.token !== undefined) {

					saveToken(response.data.token);
					saveProfile(JSON.stringify(response.data.profile));

					dispatch(
						{
							type: PROFILE.SET_PROFILE,
							payload: response.data.profile
						}
					);
					return response;
				}
			} else {
				return response;
			}

			return null;

		} catch (e) {
			debugger;
		}
	};
}


export function logout() {

	return async (dispatch) => {
		try {

			logoutStorage();

			dispatch(
				{
					type: PROFILE.CLEAR_PROFILE,
					payload: undefined
				}
			);
		} catch (e) {
			debugger;
		}
	};
}


export function setProfile(myRequest) {

	return async (dispatch) => {
		// let response = await studentApi.getModuleManifest(myRequest);

		// let payload = null;
		// if (response.status === 200) {
		// 	payload = response.data;
		// } else {
		// 	// return - we are fucked!
		// }


		let payload = {
			userId: '',
			firstName: '',
			lastName: ''
		};

		dispatch(
			{
				type: PROFILE.SET_PROFILE,
				payload: payload
			}
		);
	};
}


export function getProfileFromDb() {
	return async (dispatch) => {
		// user hit refresh and we lost 
		let savedProfile = getProfileFromStorage();

		let response = {};
		if (savedProfile !== null) {

			// we have a profile, let's get a fresh one from the db
			// if this returns undefined, then we need to logout			
			response = await profileApi.getUserProfile(savedProfile.id);

			if (response.hasError === false) {
				dispatch(
					{
						type: PROFILE.SET_PROFILE,
						payload: response.data
					}
				);
				// we got a good user
				return true;
			} else {
				// we got a bad user, logout
				return false;
			}
		}

		return false;

	};
}