export const VERSION = '08.19.24.110';
export const API_VERSION = '08.19.24.105';
export const COPYRIGHT = '2024 © Wireless Business Consultants';
export const ENDPOINTS = {
	// local dev
	//API: 'http://localhost:5001/api',
	//CALL_BACK_URL: 'http://localhost:3000/update-password'

	// dev
	API: 'https://wbc-admin-api.azurewebsites.net/api',
	CALL_BACK_URL: 'https://dsp-wireless.com/update-password'


	// prod
	//API: 'https://api.dsp-wireless.com/api',
	//CALL_BACK_URL: 'https://prodapi.caa-apps.com/reset-password's
};



// device, accessories, mobile broadbad & routers, tablets

export const RECAPTCHA = '6LeYoLYUAAAAAGJxTOuLd5UKJJ8jHLrofvBXdEpW';


export const SITE = 'DSP';
export const APP_YEAR = '2024';

export const API_METHOD = {
	POST: 'POST',
	GET: 'GET',
	DELETE: 'DELETE'
};
export const LOCAL_STORAGE = {
	PROFILE: 'wbc-profile',
	TOKEN: 'wbc-token',
	CART: 'wbc-cart',
};
export const SESSION_STORAGE = {
	CUSTOMER_ID: 'customer-id',
	AGENT_ID: 'agent-id',
	TOKEN: 'live-token'
};
export const STATUS = {
	ACTIVE: { ID: 1, VALUE: 'Active' },
	INACTIVE: { ID: 0, VALUE: 'Inactive' },
};

// purchase type
export const PURCHASE_TYPES = [
	{ value: "Subsidy", label: 'Subsidy (24 month)' },
	{ value: "EPP Financing", label: 'EPP Financing' },
	{ value: "Full Price", label: 'Full Price' }
];

// installed app options
export const INSTALLED_APPS = ["Ementor", "Google Maps", "Paycom", "Waze", "Flex", "Chime", "ADP", "Amazon A to Z"];