import { CUSTOMER as ActionTypes } from '../actionTypes';
import { customerInit } from '../../services/customerApi';

const initialState = {
	customer: customerInit,
	customers: [],
	searchText: '',
	canInsert: false
};


export const customerReducer = (state = initialState, action) => {

	switch(action.type) {
		case ActionTypes.VERIFY_CUSTOMER:

			return {
				...state,
				customers: action.payload.list,
				canInsert: action.payload.canInsert
			};
		case ActionTypes.SET_CUSTOMER_SEARCH_TEXT:

			return {
				...state,
				searchText: action.payload
			};
		case ActionTypes.CLEAR_CUSTOMER_LIST:

			return {
				...state,
				customers: action.payload
			};
		case ActionTypes.SET_CUSTOMER_LIST:

			return {
				...state,
				customers: action.payload
			};
		case ActionTypes.SET_CUSTOMER:

			return {
				...state,
				customer: action.payload
			};
		case ActionTypes.GET_CUSTOMER:

			return {
				...state,
				customer: action.payload
			};
		default:
			return state;
	};
};