import { API_METHOD, ENDPOINTS, SITE } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/product';


const productInit = {
    "id": "",
    "productCategory": "",
    "warranty": "",
    "processor": "",
    "description": "",
    "usb": "",
    "memory": "",
    "included": "",
    "productName": "",
    "durability": "",
    "productCode": "",
    "isDSPItem": "tue",
    "battery": "",
    "manufacturer": "",
    "carrier": "",
    "unitPrice": "",
    "display": "",
    "bannerMessage": "",
    "showBanner": false,
    "image_1": "",
    "image_2": "",
    "images": [],
    "quantity": "",
    "ratePlanId": "",
    "purchaseTypeId": "",
    "financeTerms": [],
    "financeTermsId": "",
    "showDeviceBanner": false,
    "deviceBannerMessage": "",
    "installedAppString": ""
};

export { productInit };

export const getAll = (category) => {
    // should only return active products
    let url = `${apiRoot}/GetAll?productType=` + category + `&site=${SITE}`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
};