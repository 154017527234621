// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// components
import { VerticalForm, FormInput } from '../../components/';
import AccountLayout from './AccountLayout';

// services
import * as profileActions from '../../redux/actions/profileActions';
import * as profileApi from '../../services/profileApi';
import { isAuthenticated } from '../../services/storageService';

/* bottom link of account pages */
const BottomLink = () => {

    return (
        <Row className="mt-3">
            <Col className="text-center">
                <p className="text-muted">
                    Don't have an account?{' '}
                    <Link to={'/register'} className="text-muted ms-1">
                        <b>Sign Up</b>
                    </Link>
                </p>
            </Col>
        </Row>
    );
};

const ResetPassword = () => {
    let history = useHistory();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [emailWasSuccess, setEmailWasSuccess] = useState(false);
    const [showEmailResults, setShowEmailResults] = useState(false);
    const [resultsMessage, setResultsMessage] = useState('');

    useEffect(() => {
        if (isAuthenticated() !== false) {
            history.push('/dsporders');
        }
    }, []);


    const [error, setError] = useState('');

    const schemaResolver = yupResolver(
        yup.object().shape({
            username: yup.string().required('Please enter an Email Address'),
            //password: yup.string().required('Please enter Password'),
        })
    );

    const onSubmit = (formData) => {
        // set loading to true
        setIsLoading(true);
        setShowEmailResults(false);

        // go straight to the api and get the response
        profileApi.sendResetToken(formData.username)
            .then((resp) => {
                setShowEmailResults(true);
                setIsLoading(false);
                if (resp.data === true) {
                    setEmailWasSuccess(true);
                    setResultsMessage("We've sent an email with instructions on how to reset your password.  Please check your email");
                } else {
                    setEmailWasSuccess(false);
                    setResultsMessage("We were not able to send you a reset password link.  Ensure you have an account.");
                }
            });



    };

    const backToLogin = () => {
        history.push('/login');
    }

    return (
        <>
            <AccountLayout bottomLinks={<BottomLink />}>
                <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-0 fw-bold">Reset Password</h4>
                    <p className="text-muted mb-4">
                        Enter your email address to recieve a password reset.
                    </p>
                </div>

                {error && (
                    <Alert variant="danger" className="my-2">
                        {error}
                    </Alert>
                )}


                {showEmailResults === true && <Alert variant="info" className="my-2">
                    {resultsMessage}
                </Alert>}
                <VerticalForm
                    onSubmit={onSubmit}
                    resolver={schemaResolver}
                    defaultValues={{ username: '', password: '' }}>
                    <FormInput
                        label={'Email Address'}
                        type="text"
                        name="username"
                        placeholder={'Enter your Username'}
                        containerClass={'mb-3'}
                    />
                    {/* <FormInput
                        label={'Password'}
                        type="password"
                        name="password"
                        placeholder={'Enter your password'}
                        containerClass={'mb-3'}>
                        <Link to="/forgot-password" className="text-muted float-end">
                            <small>Forgot your password?</small>
                        </Link>
                    </FormInput> */}

                    <div className="mb-3 mb-0 text-center">
                        <Button variant="secondary" onClick={() => backToLogin()}>
                            {isLoading === true ? 'Please wait.....' : 'Back To Login'}
                        </Button>{' '}
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading === true ? 'Please wait.....' : 'Send Reset Email'}
                        </Button>
                    </div>
                </VerticalForm>
            </AccountLayout>

        </>
    );
};

export default ResetPassword;