import { LOCAL_STORAGE } from '../appConsts';

export const isAuthenticated = () => {
	// check if we have a token in local storage
	const storedToken = localStorage.getItem(LOCAL_STORAGE.TOKEN);

	if (storedToken !== null) return true;
	// we don't
	return false;
};

export const getProfileFromStorage = () => {
	// save token to local storage
	const storedProfile = localStorage.getItem(LOCAL_STORAGE.PROFILE);
	if (storedProfile) {
		return JSON.parse(storedProfile);
	}

	// we don't have a profile saved in local storage
	return null;
};

export const saveToken = (token) => {
	// save token to local storage

	localStorage.setItem(LOCAL_STORAGE.TOKEN, token);
};

export const saveProfile = (profile) => {
	// save token to local storage

	localStorage.setItem(LOCAL_STORAGE.PROFILE, profile);
};

export const clearStorage = () => {

	// clear all local storage
	sessionStorage.clear();
	localStorage.clear();

};

export const logoutStorage = () => {

	// clear all local storage
	sessionStorage.clear();

	localStorage.removeItem(LOCAL_STORAGE.PROFILE);
	localStorage.removeItem(LOCAL_STORAGE.TOKEN);

};


export const clearCart = () => {
	// clear all local storage
	localStorage.removeItem(LOCAL_STORAGE.CART);

};