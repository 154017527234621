import { ACCOUNTS as ActionTypes } from '../actionTypes';
import { accountInit } from '../../services/accountApi';

const initialState = {
    //product: productInit,
    accountsListFull: [], // all fields
    allAccounts: [], // all fields
    singleAccount: {
        bannerMessage: '',
        battery: '',
        carrier: '',
    },
    contactListFull: [],
    allContacts: [],
};


export const accountReducer = (state = initialState, action) => {

    switch (action.type) {

        case ActionTypes.SET_ACCOUNTS:
            return {
                ...state,
                accountsListFull: action.payload,
            };

        case ActionTypes.SET_ACCOUNT_DROPDOWN_LISTS:
            return {
                ...state,
                allAccounts: action.payload,
            };

        case ActionTypes.SET_CONTACTS:
            return {
                ...state,
                contactListFull: action.payload,
            };

        case ActionTypes.SET_CONTACTS_DROPDOWN_LISTS:
            return {
                ...state,
                allContacts: action.payload,
            };

        case ActionTypes.CLEAR_CONTACT_LISTS:
            return {
                ...state,
                contactListFull: [],
                allContacts: []
            };


        default:
            return state;
    };
};



