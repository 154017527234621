import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/agent';

const agentInit = {
	"agentId": 0,
	"customerId": 0,
	"email": "",
	"firstLast": "",
	"firstName": "",
	"lastFirst": "",
	"lastName": "",
	"middleName": "",
	"phone1": "",
	"phone2": "",
	"phone3": "",
	"phoneExtension": "",
	"phoneFormatted": "",
	"phone10Digits": "",
	"fax1": "",
	"fax2": "",
	"fax3": "",
	"faxFormatted": "",
	"fax10Digits": "",
	"mobile1": "",
	"mobile2": "",
	"mobile3": "",
	"mobileFormatted": "",
	"mobile10Digits": "",
	"signature": "",
	"statusId": 1,
	"status": "",
	"dateCreated": "",
	"modifiedBy": "",
	"dateModified": "",
	"deletedBy": "",
	"dateDeleted": ""
};

export { agentInit };

export const search = (data) => {
	let url = `${apiRoot}/Search`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const getAllByCustomerId = (customerId) => {
	let url = `${apiRoot}/GetAllByCustomerId/${customerId}`;

	const options = {
		method: API_METHOD.POST
	};
	return handleFetch(url, options, true);
};

export const getSingle = (id) => {

	let url = `${apiRoot}/GetSingle/${id}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {

	}
};
export const update = (data) => {
	let url = `${apiRoot}/Update`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const insert = (data) => {
	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};