import { ORDER as ActionTypes } from '../actionTypes';
import { customerInit } from '../../services/customerApi';

const initialState = {
    selectedDevice: customerInit,
    order: [],
};


export const orderReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_ORDER:

            return {
                ...state,
                order: action.payload,
            };

        default:
            return state;
    };
};