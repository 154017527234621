import { BUILDING as ActionTypes } from '../actionTypes';
import { deviceInit } from '../../services/deviceApi';

const initialState = {
	device: deviceInit,
	devices: [],
	searchText: '',
	canInsert: false
};


export const deviceReducer = (state = initialState, action) => {

	switch(action.type) {
		case ActionTypes.VERIFY_BUILDING:

			return {
				...state,
				buildings: action.payload.list,
				canInsert: action.payload.canInsert
			};
		case ActionTypes.CLEAR_BUILDING_LIST:

			return {
				...state,
				buildings: action.payload
			};
		case ActionTypes.SET_BUILDING_LIST:

			return {
				...state,
				buildings: action.payload
			};
		case ActionTypes.SET_BUILDING:

			return {
				...state,
				building: action.payload
			};
		case ActionTypes.GET_BUILDING:

			return {
				...state,
				building: action.payload
			};
		default:
			return state;
	};
};